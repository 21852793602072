import styled from "styled-components";

export const Container = styled.div`
  padding-top: 80px;
  text-align: center;
  h1 {
    font-size: 61px;
    font-weight: 500;
  }
  h2 {
    font-size: 23px;
    font-weight: 400;
  }
  .card-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 40px;
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 15px;
    }
    padding: 10px;
  }
`;
