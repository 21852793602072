import { Container } from "./styled";

const AccuracyIntegrity: React.FC = () => {
  return (
    <Container>
      <h2>Credit Assessment Redefined</h2>
      <h1>
        Accuracy Meets <span className="gradient">Integrity</span>
      </h1>
      <img className="image" src="/images/homeImage5.png" alt="img" />
    </Container>
  );
};

export default AccuracyIntegrity;
