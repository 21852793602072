const More = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1535 6.33333H3.84583C3.56549 6.33333 3.33301 6.03111 3.33301 5.66667C3.33301 5.30222 3.56549 5 3.84583 5H16.1535C16.4339 5 16.6663 5.30222 16.6663 5.66667C16.6663 6.03111 16.4339 6.33333 16.1535 6.33333Z"
        fill="black"
      />
      <path
        d="M16.1535 10.5H3.84583C3.56549 10.5 3.33301 10.1977 3.33301 9.83329C3.33301 9.46885 3.56549 9.16663 3.84583 9.16663H16.1535C16.4339 9.16663 16.6663 9.46885 16.6663 9.83329C16.6663 10.1977 16.4339 10.5 16.1535 10.5Z"
        fill="black"
      />
      <path
        d="M16.1535 14.6667H3.84583C3.56549 14.6667 3.33301 14.3645 3.33301 14C3.33301 13.6356 3.56549 13.3334 3.84583 13.3334H16.1535C16.4339 13.3334 16.6663 13.6356 16.6663 14C16.6663 14.3645 16.4339 14.6667 16.1535 14.6667Z"
        fill="black"
      />
    </svg>
  );
};

export default More;
