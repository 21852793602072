const ArrowRight = (props: any) => {
  return (
    <svg
      width="39"
      height="23"
      viewBox="0 0 39 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_184_7099)">
        <path
          d="M37.94 10.25L28.05 0.36C27.57 -0.12 26.78 -0.12 26.3 0.36C25.82 0.84 25.82 1.63 26.3 2.11L34.08 9.88H1.23C0.55 9.88 0 10.44 0 11.11C0 11.78 0.56 12.35 1.23 12.35H34.08L26.31 20.13C25.83 20.61 25.83 21.4 26.31 21.88C26.55 22.12 26.87 22.24 27.18 22.24C27.49 22.24 27.81 22.12 28.05 21.87L37.93 11.99C38.42 11.51 38.42 10.73 37.93 10.25"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_184_7099">
          <rect width="38.3" height="22.24" fill={props.fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRight;
