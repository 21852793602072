const Linkedin = (props: any) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 45C34.9275 45 45 34.9275 45 22.5C45 10.0725 34.9275 0 22.5 0C10.0725 0 0 10.0725 0 22.5C0 34.9275 10.0725 45 22.5 45Z"
        fill="url(#paint0_linear_187_10409)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4096 32.865V19.14H12.1646V32.865H16.4096ZM16.4096 14.28C16.3871 13.05 15.5621 12.12 14.2346 12.12C12.9071 12.12 12.0371 13.0575 12.0371 14.28C12.0371 15.5025 12.8846 16.44 14.1821 16.44H14.2046C15.5621 16.44 16.4021 15.48 16.4021 14.28H16.4096ZM23.3321 32.865V25.0425C23.3321 24.6225 23.3621 24.2025 23.4821 23.91C23.8196 23.07 24.5846 22.2075 25.8671 22.2075C27.5546 22.2075 28.6046 23.16 28.6046 25.0425V32.865H32.8721V24.84C32.8721 20.5425 30.5771 18.54 27.5171 18.54C25.0046 18.54 23.9021 19.9425 23.2946 20.9025L23.3246 19.065H19.0646C19.1246 20.3775 19.0646 32.8725 19.0646 32.8725H23.3246L23.3321 32.865Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_187_10409"
          x1="0"
          y1="22"
          x2="45"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5CDA9" />
          <stop offset="0.31" stopColor="#E89B4F" />
          <stop offset="1" stopColor="#B49AC6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Linkedin;
