import styled from "styled-components";
import { GlobalContainer } from "style/global";

const PrivacyPolicy: React.FC = () => {
  return (
    <GlobalContainer>
      <Container>sss</Container>
    </GlobalContainer>
  );
};

export default PrivacyPolicy;

const Container = styled.div`
  position: relative;
`;
